<template>
	<!-- 项目-任务-原始任务详情 -->
<div>
	<GModule class="container">
		<GTitle name="任务详情" style="padding: 20px;"></GTitle>
		<div class="detail-all">
			<a-form
				v-if="info"
				class="need-box pdf-module"
        :label-col="{ span: 7}"
        :wrapper-col="{ span: 12}">
				<a-form-item label="任务ID">
				    <a-input :value="info.id" disabled/>
				</a-form-item>
				<a-form-item label="任务类型">
				    <a-input :value="info.ddate.name" disabled/>
				</a-form-item>
				<a-form-item label="状态">
				    <a-input :value="info.mix_status_text" disabled/>
				</a-form-item>
				<a-form-item label="指派给">
				    <a-input :value="info.user ? info.user.nickname :'无'" disabled />
				</a-form-item>
				<a-form-item label="静态开发时间">
				    <a-input v-if="info.staticinfo&&info.staticinfo.begin_date" :value="info.staticinfo.begin_date + '至' + info.staticinfo.end_date" disabled />
					<p v-else>无</p>
				</a-form-item>
				<a-form-item label="静态工时">
				    <a-input v-if="info.staticinfo&&info.staticinfo.hour" :value="info.staticinfo.hour" disabled/>
					<p v-else>0</p>
				</a-form-item>
				<a-form-item label="联调时间">
				    <a-input v-if="info.unioninfo&&info.unioninfo.begin_date" :value="info.unioninfo.begin_date + '至' + info.unioninfo.end_date" disabled/>
					<p v-else>无</p>
				</a-form-item>
				<a-form-item label="联调工时">
				    <a-input v-if="info.unioninfo&&info.unioninfo.hour" :value="info.unioninfo.hour" disabled/>
					<p v-else>0</p>
				</a-form-item>
				<a-form-item label="任务描述">
				    <a-textarea style="height: 150px;" :value="info.describe" disabled/>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:10 }">
				  <a-button class="btn clo6" @click="goBack()">返回</a-button>
				  <a-button 
					class="btn clo3" 
					@click="updateBtn(info.id,info.describe)"
					v-if="!authority.de_index">修改</a-button>
				  <a-button class="btn clo1" v-if="authority.complete&&info.staticinfo&&(info.staticinfo.date_status=='overdue'||info.staticinfo.date_status=='incomplete'||info.staticinfo.date_status=='fail')" @click="modals={ids:info.id,title:'确认静态完成',type:'complete',typ:'static',list:[]}">完成静态</a-button>
				  <a-button class="btn clo4" v-if="authority.complete&&info.unioninfo&&(info.unioninfo.date_status=='overdue'||info.unioninfo.date_status=='incomplete'||info.unioninfo.date_status=='fail')" @click="modals={ids:info.id,title:'确认联调完成',type:'complete',typ:'union',list:[]}">完成联调</a-button>
				  <a-button class="btn clo4" v-if="authority.complete&&info.allinfo&&(info.allinfo.date_status=='overdue'||info.allinfo.date_status=='incomplete'||info.allinfo.date_status=='fail')" @click="modals={ids:info.id,title:'确认完成',type:'complete',typ:'all',list:[]}">完成</a-button>
					<a-modal v-if="modals" :maskClosable="!1" v-model:visible="omodals" :title="modals.title" @ok="editType(modals.ids,modals.type,modals.typ,modals.list)" @cancel="modals=null">
						<a-upload
							v-if="modals.list"
							name="file"
							v-model:file-list="modals.list"
							:maxCount="8"
							list-type="picture-card"
							:customRequest ="customRequest"
							@remove="remove">
							<div class="addimg" v-if="modals.list.length < 8">
								<plus-outlined />
								<p style="color: #999999;">点击上传图片</p>
							</div>
						</a-upload>
						<p v-else v-text="modals.center||''"></p>
					</a-modal>
				</a-form-item>
			</a-form>
			<div v-if="info" class="pdf-module">
				<Demand :details="info.demand" noinfo="3" />
				<a-form :label-col="{ span: 7}" :wrapper-col="{ span: 12}">
					<a-form-item label="已完成页面图">
						<template v-if="info.ddate&&info.ddate.name=='原始需求'">
							<div class="overimg-box" v-if="info.staticinfo">
								<p class="p">静态</p>
								<div v-if="info.staticinfo.complete_images.length > 0" 
									class="overimg" 
									@click="openSwiper(info.staticinfo.complete_images)">
									<a-image 
									:preview="false" 
									:width="65" 
									:src="itm" 
									v-for="(itm,ind) in info.staticinfo.complete_images" 
									:key="ind"/>
								</div>
								<p class="overimg"  v-else>无</p>
								<div class="overimg-btn" v-if="authority.task_opt&&info.staticinfo&&info.staticinfo.date_status=='wait'">
									<a-button class="btn clo1" size="small" style="margin-bottom: 5px;" @click="submit(['static','confirm'])">确认静态</a-button>
									<a-button class="btn clo2" size="small" @click="submit(['static','fail'])">驳回静态</a-button>
								</div>
							</div>
							<div class="overimg-box" v-if="info.unioninfo" style="margin-top: 10px;">
								<p class="p">联调</p>
								<div v-if="info.unioninfo.complete_images.length > 0" 
									class="overimg" 
									@click="openSwiper(info.unioninfo.complete_images)">
									<a-image 
										:preview="false" 
										:width="50" 
										:src="itm" 
										v-for="(itm,ind) in info.unioninfo.complete_images" 
										:key="ind"/>
								</div>
								<p class="overimg"  v-else>无</p>
								<div class="overimg-btn" v-if="authority.task_opt&&info.unioninfo&&info.unioninfo.date_status=='wait'">
									<a-button class="btn clo1" size="small" @click="submit(['union','confirm'])" style="margin-bottom: 5px;">确认联调</a-button>
									<a-button class="btn clo2" size="small" @click="submit(['union','fail'])">驳回联调</a-button>
								</div>
							</div>
						</template>
						<div v-else-if="info.allinfo" class="overimg-box" style="margin-top: 10px;">
							<!-- <p class="p">新增变更</p> -->
							<div v-if="info.allinfo.complete_images.length > 0" 
								class="overimg" 
								@click="openSwiper(info.allinfo.complete_images)">
								<a-image 
									:preview="false" 
									:width="50" 
									:src="itm" 
									v-for="(itm,ind) in info.allinfo.complete_images" 
									:key="ind"/>
							</div>
							<p class="overimg"  v-else>无</p>
							<div class="overimg-btn" v-if="authority.task_opt&&info.allinfo&&info.allinfo.date_status=='wait'">
								<a-button class="btn clo1" size="small" @click="submit(['all','confirm'])" style="margin-bottom: 5px;">确认</a-button>
								<a-button class="btn clo2" size="small" @click="submit(['all','fail'])">驳回</a-button>
							</div>
						</div>
					</a-form-item>
					<a-form-item  :wrapper-col="{ offset:7}">
					  <a-button v-if="info.ddate&&info.ddate.name=='原始需求'" class="btn clo1" @click="openDownloadImgs()">下载</a-button>
					  <a-button v-else class="btn clo1" @click="openDownloadImg()">下载</a-button>
					</a-form-item>

				</a-form>


			</div>


			
		</div>
	</GModule>
</div>			
</template>

<script>
import Demand from '@/components/PdDemand'; // Demand
	export default{
		name:'TaskProjectDetail',
	  components:{
	  	Demand,
	  	// Task,
	  	// Cases,
		},
		data(){
			return{
				id:0,
				info:null,
				modals:null,
			}
		},
		computed:{
			isUpdateFresh(){
				return this.$store.state.isUpdateFresh
			},
	    authority(){
	      var data = {};
	      this.$store.state.params.forEach(item=>{
	        data[item.name.split('/')[2]] = true;
	      })
	      return data
	    },
			omodals(){
				return !!this.modals
			},
		},
		watch:{
			isUpdateFresh(val,oldvalue){
				// 修改完成后回显
				if(val){
					this.getDetail()
					this.$store.commit('setUpdateId',null) // 重置Id
					this.$store.commit('setUpdateDescribe',"") //重置需求描述
					this.$store.commit('setIsTaskUpdate',0) // 任务修改标识
				}
			}
		},
		created() {
			this.id = this.$route.query.id || 0
	      this.$store.commit('setStore',{ name:'hideSider', data:1 })
			if(this.id){
				this.getDetail()
			}
		},
		methods:{
	  	submit(type){
	  		// this.editType(this.id,type,typ)
	  		this.modals = {
	  			title:type[1]=='confirm'?'是否确认':'是否驳回',
					ids:this.id,
					type:type[1],
					typ:type[0],
					center:'是否'+(type[1]=='confirm'?'确认':'驳回')+(type[0]!='all'?'此':'')+(type[0]=='static'?'静态':'联调')+'？'
				}
	  	},
			async editType(ids,type,typ){
				var url = '/task/task_opt',
					data = {
						ids:ids,
						type:typ
					};
				if(type=='complete'){
					url = '/task/complete';
					data.images = this.modals.list.map(item=>item.url);
				}else{
					data.opt = type;
				}
				var res = await this.$utils.api.post({
					url:url,
					data:{...data},
					result:1
				})
				if(res.code==1){
					// if(type=='complete'){
						this.modals = null;
					// }
					this.getDetail()
					this.$utils.msgSuc('提交成功！');
				}
			},
			openSwiper(arr){
				// 弹出图片列表
				this.$store.commit('setSwiperArr',arr)
				this.$store.commit('setSwiper',1)
			},
			openDownloadImg(){
				// 完成图片下载
				let imgs = this.info.allinfo ? this.info.allinfo.complete_images : []
				if(imgs.length == 0){
					this.$utils.msgWar('暂无可下载的图片')
					return
				}
				let arr = []
				for(let i in imgs){
					arr.push({url:imgs[i],isCheck:false})
				}
				this.$store.commit('setImgOtherArr',arr)
				this.$store.commit('setImgOther',1)
			},
			openDownloadImgs(){
				// 静态、联调图片下载
				let staticArr = this.info.staticinfo ? this.info.staticinfo.complete_images : [] // 静态
				let unionArr = this.info.unioninfo ? this.info.unioninfo.complete_images :[]// 联调

				
				
				if(staticArr.length == 0 && unionArr.length == 0){
					this.$utils.msgWar('暂无可下载的图片')
					return
				}
				let arr = []
				for(let i in staticArr){
					arr.push({url:staticArr[i],isCheck:false})
				}
				
				let arr2 = []
				for(let j in unionArr){
					arr2.push({url:unionArr[j],isCheck:false})
				}
				this.$store.commit('setStaticImgs',arr)
				this.$store.commit('setAlignmentImgs',arr2)
				this.$store.commit('setImgCheck',1)
			},
			async getDetail(){
				let res = await this.$utils.api.post({
					url:'/task/getRow',
					data:{
						ids:this.id
					},
					result:1
				})
				if(res.code == 1){
					if(res.data.staticinfo&&typeof res.data.staticinfo.complete_images == 'string'){
						res.data.staticinfo.complete_images = res.data.staticinfo.complete_images.split(',')
					}
					if(res.data.unioninfo&&typeof res.data.unioninfo.complete_images == 'string'){
						res.data.unioninfo.complete_images = res.data.unioninfo.complete_images.split(',')
					}
					if(res.data.allinfo&&typeof res.data.allinfo.complete_images == 'string'){
						res.data.allinfo.complete_images = res.data.allinfo.complete_images.split(',')
					}
					
					if(res.data.demand.images != null && res.data.demand.images.length > 0 ){
						res.data.demand.imgArr = res.data.demand.images.split(',')
					}else{
						res.data.demand.imgArr = []
					}
					
					this.info = res.data
					this.$store.commit('setStore',{ name:'history', data:{list:res.data.history,type:'task',id:res.data.id} })


					// this.caseList = res.data.cases
				}
			},
				async customRequest (e) {
					// 执行图片上传
					let that = this
					let res = await this.$utils.api.upLoad(e)
					if(res){
						setTimeout(()=>{
							that.$utils.msgSuc('上传成功')
							// 上传成功修改文件状态
							that.modals.list[that.modals.list.length-1].status = 'done' 
							// 图片封面预览
							that.modals.list[that.modals.list.length-1].url = res.data.fullurl
						},500)
					}
				},

			updateBtn(id,describe){
				// 编辑
				this.$store.commit('setIsRecordAdd',1) //弹出修改
				this.$store.commit('setUpdateId',id) // 修改Id
				this.$store.commit('setUpdateDescribe',describe) //回显
				this.$store.commit('setIsTaskUpdate',1) //任务
			},
			goBack(){
	      this.$store.commit('setStore',{ name:'hideSider', data:0 })
	      this.$store.commit('setStore',{ name:'history', data:null })
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0 0 50px 0;
	.detail-all{
		padding: 30px 0;
		display: flex;
		.pdf-module{
			flex:1;
		}
		.need-box{
			.btn{
				margin-right: 10px;
			}
		}
		.img-scroll{
			height: 90px;
			overflow-x: auto;
			overflow-y: hidden;
			display: flex;
			align-items: center;
			&::-webkit-scrollbar {
				height: 0px; // 控制滚动条
			}
			&::-webkit-scrollbar-thumb{
				background-color: #a5ddff; // 滚动条颜色
			}
			&:hover{
				&::-webkit-scrollbar {
					height: 8px;

				}
			}
		}
		.overimg-box{
			display: flex;
			justify-content: space-between;
			align-items: center;
			white-space: nowrap;
			width:100%;
			flex-wrap: wrap;
			margin-bottom:10px;
			.p{
				width: 100%;
				margin: 0;
				padding: 0;
				font-size: 12px;
				line-height:30px;
			}
			.overimg{
				display: flex;
				padding:5px;
				background:#f5f5f5;
				height: 70px;
				overflow: hidden;
				flex:1;
				min-width:100px;
				margin:0;
			}
			.overimg-btn{
				width: 80px;
				margin-left:20px;
				.ant-btn{
					font-size: 12px;
					display: block;
				}
			}
		}
	}
}
</style>